import { Container, Row, Col, Image } from "react-bootstrap";

function MidService(props) {
    return (
        <Container data-aos="flip-left" className="p-5 g-0">
            <Row className="justify-content-center align-items-center g-0">
                <Col>
                    {props.iconName ? <i className={props.iconName} style={{ fontSize: 50, color: "black" }} ></i>
                    : <Image src={props.image} style={{ fontSize: 60, color: "white"}}></Image>}
                </Col>
                <Col>
                    <h4 style={{ fontWeight: "bold", color: "black" }}>{props.serviceName}</h4>
                </Col>
            </Row>


        </Container>
    )

}

export default MidService;