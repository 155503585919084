import { Carousel } from "react-bootstrap";
import Img25 from "../../../assets/img/file-25.jpg"

function ImpressumBild(props) {
    return (
        <Carousel className="hideCarouselButtons">
        <Carousel.Item>
          <img
            style={{ objectFit: "cover" }}
            className="d-block w-100"
            src={Img25}
            height={400}
            alt={props.caption + " Bild"}
          />
          <Carousel.Caption>
            <h1 style={{ fontSize: 55 }}>{props.caption}</h1>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    )
}
export default ImpressumBild;