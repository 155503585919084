import { Container, Row, Col } from "react-bootstrap";
import MainCard from "./MainCard";
import {useTranslation, Trans} from "react-i18next";

function MidSection() {
    const {t} = useTranslation();

    return (
        <Container className="bg-light mt-5 p-3">
          
            <Row className="justify-content-center">
            <MainCard cardIcon="bi-alarm" cardTitle={t("cards.fast_response.title")} cardText={t("cards.fast_response.text")}></MainCard>
            <MainCard cardIcon="bi-shield-check" cardTitle={t("cards.quality.title")} cardText={t("cards.quality.text")}></MainCard>
            <MainCard cardIcon="bi-diagram-3" cardTitle={t("cards.offers.title")} cardText={t("cards.offers.text")}></MainCard>
            <MainCard cardIcon="bi-pencil-square" cardTitle={t("cards.certified.title")} cardText={t("cards.certified.text")}></MainCard>

            </Row>

        </Container>

    )
}

export default MidSection;