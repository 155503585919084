import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { useTranslation, Trans } from 'react-i18next';
import PaintingImage from "../../../assets/img/painting.jpeg";
import ReactMarkdown from 'react-markdown';

function Section() {
    const {t} = useTranslation();
    return (
        <Container className="bg-light info-section">
            <Row data-aos="zoom-in-up" className="align-items-center p-5 bg-light">
                <Col sm>
                    <ReactMarkdown>{t("surfacing.painting.text")}</ReactMarkdown>
                </Col>
                {/* <Col sm>
                    <Image className="shadow p-2 rounded" src={PaintingImage} height={300} fluid></Image>
                </Col> */}
                {/* Bild entfernt (Auf Wunsch von Florian) */}
            </Row>
        </Container>
    )
}
export default Section;