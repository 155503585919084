import { Container, Image, Row, Col } from "react-bootstrap";
import LocationBild from "../../../../assets/img/location-rs-mechanik.png";
import "./InfoSection.css"
import { useTranslation, Trans } from 'react-i18next';

import Img25 from "../../../../assets/img/file-25.jpg";

function InfoSection4() {
    const {t} = useTranslation();

    return (
        <section id="section-2" className="bg-darker-blue info-section">
            <Row data-aos="zoom-in-up" className="align-items-center p-5 bg-darker-blue">
                {/* <Col sm>
                    <Image data-aos="zoom-in-up" className="p-2" src={Img25} height={400}></Image>
                </Col> */}
                <Col sm>
                    <h1 data-aos="zoom-in-up" style={{ color: "white" }}>
                        {t("company.section_3.title")}
                    </h1>

                    <p data-aos="zoom-in-up" style={{ color: "white", whiteSpace: "pre-line" }}>
                        {t("company.section_3.text")}
                    </p>
                </Col>
            </Row>
        </section>
    )
}
export default InfoSection4;