import {
  Container,
  Col,
  Row,
  FloatingLabel,
  Form,
  Button,
} from "react-bootstrap";
import "./ColorClasses.css";
import { useTranslation, Trans } from 'react-i18next';
import GoogleMapsFrame from "../GoogleMapsFrame";

function KontaktPage() {
  const { t } = useTranslation();

  return (
    <section className="bg-light">
      <div id="map-container-google-1" className="z-depth-1-half map-container">
        <GoogleMapsFrame />
      </div>
      <div id="contact-form" className="mt-3 p-5" data-aos="zoom-in-up">
        <h1>{t("contactpage.messageus")}</h1>
        <h4>
          {t("contactpage.contactustext")}
        </h4>
        {/* <Form data-aos="zoom-in-up">
          <Row className="p-3">
            <Col sm>
              <Form.Group className="mb-3" controlId="formGroupName">
                <FloatingLabel controlId="floatingInputGrid" label="Name">
                  <Form.Control type="text" placeholder="" />
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col sm>
              <Form.Group className="mb-3" controlId="formGroupEmail">
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Email Adresse"
                >
                  <Form.Control type="email" placeholder="" />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <Row className="p-3">
            <Col sm>
              <Form.Group className="mb-3" controlId="formGroupMessage">
                <FloatingLabel
                  controlId="floatingTextarea"
                  label={t("contactpage.yourmessagelabel")}
                >
                  <Form.Control
                    style={{ height: "100px" }}
                    as="textarea"
                    type="text"
                    placeholder=""
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <Button
            style={{ fontSize: 20, fontFamily: "sans-serif" }}
            type="submit"
            className="bg-darker-blue"
          >
            {t("contactpage.sendmessage")}
          </Button>
        </Form> */}
        <h5>E-Mail: info@rs-mechanik.de</h5>
      </div>
    </section>
  );
}

export default KontaktPage;
