import { Container, Row, Col, Nav } from "react-bootstrap";
import "./FooterComponent.css"
import { useTranslation, Trans } from 'react-i18next';

function FooterComponent() {
    const {t} = useTranslation();

    return (
        <Container className="bg-light p-5" fluid>
            <Row>
                <Col>
                    <Nav className="flex-column mb-4">
                        <p className="footer-title">Unser Angebot</p>
                        <Nav.Link href="/" className="footer-link">{t("navigation.home")}</Nav.Link>
                        <Nav.Link href="unternehmen" className="footer-link">{t("navigation.about_us")}</Nav.Link>
                    </Nav>
                </Col>
                <Col>
                    <Nav className="flex-column mb-4">
                        <p className="footer-title">{t("navigation.company")}</p>
                        <Nav.Link href="leistungen" className="footer-link">{t("navigation.services")}</Nav.Link>
                        <Nav.Link href="qualitaet" className="footer-link">{t("navigation.quality")}</Nav.Link>
                    </Nav>
                </Col>
                <Col>
                    <Nav className="flex-column mb-4">
                        <p className="footer-title">{t("navigation.contact")}</p>
                        <Nav.Link href="tel:094612406" className="footer-link">{t("navigation.phone")}</Nav.Link>
                        <Nav.Link href="tel:094612406" className="footer-link">{t("navigation.mobile")}</Nav.Link>
                        <Nav.Link href="kontakt" className="footer-link">{t("navigation.further_contact")}</Nav.Link>
                        <Nav.Link href="impressum" className="footer-link">{t("navigation.imprint")}</Nav.Link>
                        <Nav.Link href="datenschutz" className="footer-link">{t("navigation.privacy")}</Nav.Link>
                    </Nav>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <p>Copyright &copy; RS-Mechanik {new Date().getFullYear()}</p>
                    
                </Col>
                <Col>

                </Col>
                <Col>
                <p style={{fontSize: 10}}>Services Icons <a href="https://www.flaticon.com/free-icons/drill" title="drill icons"> created by Freepik - Flaticon</a> </p>
                </Col>
            </Row>
        </Container>
    )
}

export default FooterComponent;