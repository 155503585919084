import ImageCarousel from "../Main/ImageCarousel";
import { useTranslation, Trans } from 'react-i18next';
import ServiceCard from "../Unternehmen/ServiceCard";
import LCAlphaIII from "../../../assets/img/cnc/LCAlphaIII.png";
import { Row, Col } from "react-bootstrap";
import CNCMachineCard from "./CNCMachineCard";
import LaserSection from "./Laser/LaserSection";

function ServicePage() {
  const {t} = useTranslation();

  const machines = [
    {
      name: "Amada LC Alpha III",
      info: "",
      entries: [2520, 1550], // Verfahrsbereich siehe Amadaseite
      infotext: "",
      picture: LCAlphaIII,
      link: "https://www.amada.de/de/laserschneidanlagen/alpha3/technische-daten.html"
    },
  ]
  return (
    <section className="bg-light">
      <section className="bg-light">
        <ImageCarousel></ImageCarousel>
      </section>
      <LaserSection blue={true} text={t("services.laser.section_1")} />
      <LaserSection blue={false} image={LCAlphaIII} text={t("services.laser.section_2")} />
      </section>
  );
}

export default ServicePage;
