import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { useTranslation, Trans } from 'react-i18next';
import CertifiedImage from "../../../assets/img/certified.png";
import ReactMarkdown from 'react-markdown';

function Section() {
    const {t} = useTranslation();
    return (
        <section className="bg-darker-blue info-section text-white">
            <Row data-aos="zoom-in-up" className="align-items-center p-5 bg-darker-blue">
                <Col sm>
                    <Image className="p-2" src={CertifiedImage} height={200}></Image>
                </Col>
                <Col sm>
                    <ReactMarkdown>{t("quality.certified.text")}</ReactMarkdown>
                </Col>
            </Row>
        </section>
    )
}
export default Section;