import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { useTranslation, Trans } from 'react-i18next';
import WeldingImage from "../../../assets/img/welding.jpeg";
import GrindingImage from "../../../assets/img/grinding.jpeg";
import ReactMarkdown from 'react-markdown';

 function UnCryptMailto(s) {
	var n=0;
	var r="";
	for(var i=0; i < s.length; i++) {
		n=s.charCodeAt(i);
		if (n>=8364) {n = 128;}
		r += String.fromCharCode(n-(1));
	}
	return r;
 }


function Section() {
    const {t} = useTranslation();
    return (
        <Container className="bg-light info-section">
            <Row data-aos="zoom-in-up" className="align-items-center p-5 bg-light">
                <Col sm>
                    <ReactMarkdown style={{display: "inline"}}>{t("jobs.post")}</ReactMarkdown>
                    {/* mailto: nbjmup; */}
                    <p style={{display: "inline"}}>{UnCryptMailto("jogpAst.nfdibojl/ef")}</p>
                </Col>
                <Col sm>
                    <Image className="p-2" src={WeldingImage} height={400} fluid></Image>
                </Col>
            </Row>
        </Container>
    )
}
export default Section;