import ImageCarousel from "../Main/ImageCarousel";
import { useTranslation, Trans } from 'react-i18next';
import ServiceCard from "../Unternehmen/ServiceCard";
function ServicePage() {
  const {t} = useTranslation();
  return (
    <section className="bg-light">
      <section className="bg-light">
        <ImageCarousel></ImageCarousel>
      </section>
      <section className="bg-light">
        <ServiceCard blue={false} title={"services.other_services.title"} subtitle={"services.other_services.subtitle"} text={"services.other_services.text"} />
      </section>
      <section className="bg-light">
        <ServiceCard blue={false} title={"services.other_services.in_house.title"} text={"services.other_services.in_house.text"} />
      </section>
      </section>
  );
}

export default ServicePage;
