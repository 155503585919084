
import GalleryComponent from "../GalleryComponents/GalleryComponent";
import ImageCarousel from "./Main/ImageCarousel";
import VertraulichkeitSection from "./Qualitaet/Vertraulichkeit";
import ZertifizierungSection from "./Qualitaet/Zertifizierung";
import KommunikationSection from "./Qualitaet/Kommunikation";

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const pImages = importAll(require.context('../../assets/img/portfolio', false, /\.(png|jpe?g|svg)$/));



function QualitaetPage() {
    return (
        <section className="bg-light">
            <ImageCarousel>
            </ImageCarousel>
            {/* <h1 className="pt-4">Unser Portfolio</h1>
            <GalleryComponent images={pImages}>

            </GalleryComponent> */}
            <VertraulichkeitSection></VertraulichkeitSection>
            <ZertifizierungSection></ZertifizierungSection>
            <KommunikationSection></KommunikationSection>
            
        </section>

    )
}

export default QualitaetPage;