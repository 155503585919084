import PictureComponent from "./Main/PictureComponent";
import MidSection from "./Main/MidSection";
import { Container } from "react-bootstrap";
import MidServices from "./Main/MidServices";
import ImageCarousel from "./Main/ImageCarousel";
import MainText from "./Main/MainText";

function MainPage() {
    return (
        <section className="bg-light">
            <ImageCarousel>
            </ImageCarousel>

            <MidSection>

            </MidSection>
            <MidServices>

            </MidServices>
            <MainText>
                
            </MainText>
        </section>

    )
}

export default MainPage;