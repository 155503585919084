import { Container, Image, Row, Col, Button } from "react-bootstrap";
import "./InfoSection.css"
import { useTranslation, Trans } from 'react-i18next';
import LocationBild from "../../../../assets/img/location-rs-mechanik.png";

function InfoSection3() {
    const {t} = useTranslation();
    
    return (
        <Container className="bg-light info-section">
            <Row className="align-items-center p-5 bg-light">
                <Col sm>
                    <Row>
                        <h2 data-aos="zoom-in-up">{t("company.section_4.title")}</h2>
                        <p data-aos="zoom-in-up" style={{whiteSpace: "pre-line"}}>
                            {t("company.section_4.text")}
                        </p>
                    </Row>
                    {/* <Row>
                        <Button data-aos="flip-left" href="/qualitaet" className="btn btn-outline-unique waves-effect">{t("company.general.open_portfolio")} <i class="bi-magic"></i></Button>
                    </Row> */}


                </Col>
                <Col sm>
                    <Image data-aos="zoom-in-up" className="p-2" src={LocationBild} height={400} fluid></Image>
                </Col>
            </Row>
        </Container>
    )
}
export default InfoSection3;