import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          // here we will place our translations...
          navigation: {
            home: "Home",
            company: "Company",
            quality: "Quality",
            contact: "Contact",
            imprint: "Imprint",
            privacy: "Privacy",
            services: "Services",
            jobs: "Jobs",
            surfacing: "Surfacing",
            production: "Production",
            cnc_laser: "CNC Laser & Edging",
            cnc_milling: "CNC Milling & Turning",
            chromatizing: "Chromatizing",
            other_services: "Other services",
            message404: "We could not find this page.",
            message_go_startpage: "Return to home",
            leave_page_title: "You are about to leave the page",
            leave_page_text: "If you continue, you will be forwarded to a third-party site. Link: ",
            button_leave: "Close",
            continue: "Continue",
            about_us: "About us",
            phone: "Phone",
            mobile: "Mobile",
            further_contact: "Further contact infos",
            
          },
          footer: {
            our_offers: "",

          },
          company: {
            general: {
              open_portfolio: "Open Portfolio"
            },
            section_1: {
              title: "About the Company",
              text: "In 1990, the company RS-Mechanik was founded by Reinhold Strasser.\n Thanks to our many years of experience, we know the demands of our customers with regard to quality and cost-effective manufacturing.\nOur strength is to master the tasks set for us quickly and with high precision. Reliability and quality are appreciated by our customers and are of great importance to us.\n We build on a long-term cooperation."
            },
            section_2: {
              title: "Our Team",
              text: "An experienced and highly qualified team of 20 employees guarantees the flawless execution of demanding machining and assembly work.\n\nAs a future oriented company we are always looking for new employees and apprentices!"
            },
            section_3: {
              title: "Quality Production",
              text: "Quality is the be-all and end-all for us in the manufacturing process.\n\nSince precise manufacturing is indispensable in the CNC industry, we constantly check our manufacturing processes and adapt them if necessary."
            },
            section_4: {
              title: "Advantageous Location",
              text: "Due to our location in the industrial area, it is possible for us to pass on parts for processing to cooperating companies on request. Through carefully selected partners we offer additional services, such as special surface coating processes. The location of the company building enables us to cooperate quickly and thus to have an overall process with a focus on quality and fast processing time."
            }
          },
          main: {
            about_us: {
              text: "### About the company \nOur company specializes in high-quality CNC laser and milling services for a wide range of industries. Thanks to our state-of-the-art equipment and skilled team of technicians, we are able to provide precise and efficient machining services for all types of products - from prototypes to high-volume production runs. \n#### Huge selection of services\nWhether you need complex 3D shapes or intricate details on a flat surface, our CNC lasers and mills can handle it all with speed and accuracy. Plus, we offer a variety of materials to choose from, including metals, plastics and composites, so you can find the perfect solution for your project. \n#### High-quality service \nIn addition to fabricating parts, we also provide services such as chromating, anodizing, or painting for their parts. So if you need reliable and high-quality CNC laser and milling services, you've come to the right place. Contact us today to discuss your project and get a quote. \n#### Partnered with TU Fast Racingteam of the TU Munich \nWe are proud to be a partner of the TU Fast Racingteam Munich - our company works on essential parts of the racing car."
            }
          },
          imprint: {
            companyname: "RS-Mechanik GmbH u. Co. KG",
            address: "Carl-Zeiss-Str. 3 93426 Roding",
            phone: "Phone: +49 (0)9461 2406",
            fax: "Fax: +49 (0)9461 4978",
            email: "E-Mail: info@rs-mechanik.de",
            companyseat: "Seat of company: Roding",
            registercourt: "Registry Court: Regensburg, HRA 6626, HRB 8469",
            ceo: "CEO: Reinhold Strasser",
            leader: "Managing director: Tobias Strasser",
            ustnumber: "USt-ID number: DE 222 841 262",
            taxnumber: "Tax number: 211/173/01505",
            hinweis: "Information according to §5 TMG:"
          },
          contactpage: {
            messageus: "Message us!",
            contactustext: "Contact us if you have an inquiry - we are going to response as soon as possible!",
            emailaddresslabel: "Email Address",
            yourmessagelabel: "Your Message",
            sendmessage: "Send message",
            enable_google_maps: {
              text: "To view the location of RS-Mechanik Google Maps is used. If you agree with the usage of Google Maps, press the button below to load the Google Maps embedded iFrame.",
              button: "Enable Google Maps"
            }
          },
          jobs: {
            post: "### Become part of the RS-Mechanik family \nAs a modern CNC manufacturing company, we are constantly looking for competent employees. We offer our employees flexibility and freedom in terms of working hours: no shift work and working hours can be freely chosen among normal working hours. \n#### Positive working atmosphere \nWe offer attractive bonuses for our employees and attach great importance to a pleasant working environment. We are looking for both experienced professionals and entry-level employees who want to pursue a career in CNC manufacturing. \n#### Apply now! \nIf you are interested in a career in our company, we look forward to receiving your application including your resume. Please send your documents by e-mail to "
          },
          surfacing: {
            chromatizing: {
              text: "### Robust coating \nIn addition to our CNC services, we also offer professional surface treatment by chromating. With this technique, we can provide your products with a high-quality, resistant and attractive chromium coating that protects them from corrosion and other damage. \n#### Chrome III passivation with Surtec \nWe only use products from the renowned supplier Surtec for this purpose to ensure that we always offer you the best possible quality. Our experienced team of technicians will work carefully and professionally to ensure that your products are chrome plated to perfection.",
            },
            anodizing: {
              text: "### High quality anodizing \nOur company offers high quality anodizing service for coating parts. Anodizing is a process in which a thin layer of aluminum oxide is applied to the surface of metal parts to improve their corrosion resistance, hardness and appearance. \nWe outsource this service and thus offer it directly to our customers. \nAnodizing is a reliable and durable coating method suitable for a wide range of industries, including automotive, electronics, and aerospace. We are proud to offer this service and look forward to helping you coat your parts.",
            },
            painting: {
              text: "### Painting parts \nOur experienced team has the skills and know-how to paint your parts perfectly to ensure a high quality and durable finish.\nWhether it's a small batch production or single parts, we adapt our process accordingly and guarantee you a perfect result. We work with modern painting equipment and highly robust industrial paint to ensure a uniform and quality surface coating. The paint is then professionally baked in the drying oven. \Please feel free to contact us if you have any further questions or need a quote."
            },
          },
          quality: {
            communication: {
              text: "### Customer satisfaction first \nOur customers are very important to us and we do everything we can to provide them with high quality products and services. We are proud that our customers are always satisfied and we have a high level of customer satisfaction. \n\nHowever, should any problems or dissatisfaction arise, we take them seriously and work closely with our customers to find solutions together. We believe that good communication is key to a successful working relationship and do everything we can to communicate openly and transparently to ensure that any issues are resolved quickly and effectively. \n\nWe value the trust our customers place in us and pride ourselves on providing them with outstanding customer service."
            },
            certified: {
              text: "### Certified Quality Standard \nOur company is proud to be certified and to meet the highest quality standards. We specialize in developing products and solutions for the **food, medical, aerospace and automotive industries** and work closely with our customers to develop customized solutions that meet their needs. \n\nOur team consists of experienced professionals who specialize in developing products for these industries and have in-depth knowledge and skills in materials, manufacturing technologies and quality management. We pride ourselves on providing our customers with high-quality, reliable products and solutions, and we do everything we can to exceed their expectations."
            },
            encryption: {
              text: "### Your data is safe. \nAt our company, we place the utmost importance on the confidentiality and security of all customer data and drawings. We are committed to keeping all information provided to us by our customers strictly confidential and to not disclose any information to third parties. \nWe have implemented extensive security measures to ensure that all customer data and drawings are protected and accessible only by authorized individuals. We understand that the confidentiality of customer information is of great importance and do everything possible to protect it. \nWe appreciate the trust our customers place in us and are happy to engage in a dialogue should you have any questions regarding security concepts."
            }
          },
          services: {
            chrome: {
                title: "Surfacing by Chromatizing",
                subtitle : "",
                text: "Besides our CNC services, we also offer professional surface treatment by chromating. \nWith this technique, we can provide your products with a high-quality, resistant and attractive chromium layer that protects them from corrosion and other damage. \n#### Chrome III passivation with Surtec \nWe only use products from the renowned supplier Surtec for this purpose to ensure that we always offer you the best possible quality. Our experienced team of technicians will work diligently and professionally to ensure your products are chrome plated to perfection. Contact us today to learn more about our chromating services and how we can help you improve your products. We look forward to hearing from you!"
            },
            general: {
                close: "Close",
                contact: "Contact"
            },
            laser: {
              section_1: "## Sheet metal processing \nOur CNC laser / edging department specializes in the production of housings and covers. Our lasers are the first instance in the sheet metal department, producing basic components for enclosures and covers from raw material. Our advanced laser technology allows us to manufacture a wide variety of products, including PC enclosures, specialized medical machinery, cameras, and even solid steel parts up to 10mm thick, as well as pipe and heating system components.",
              section_2: "## Manufacturing process \nIn combination with our edge-punching-pressing machine, the laser allows us to manufacture enclosures from all materials that can later be treated by [surface treatment](../beschichtung). Our goal is to produce precise and high quality enclosures and covers that meet our customers' requirements in terms of design, durability and function. The travel range of our machine, the Amada LC Alpha III is 2520 x 1550mm (X x Y axis)."
            },
            milling: {
              title: "Professional CNC Milling services",
              subtitle: "",
              text: "Our machinery is equipped with modern and powerful CNC milling machines suitable for a wide range of manufacturing tasks.\n\nWe support a wide range of materials: from almost all types of steel and special metals such as Monel to plastics such as PEEK, POM and Teflon, as well as all metallic materials such as steel, stainless steel, copper, aluminum, brass and more can be used for turning and milling.",
              fast_response: {
                title: "Fast execution for priority jobs",
                text: "We are able to respond quickly to customer needs and execute milling orders. Especially in special circumstances, such as urgent or prioritized orders, we can ensure fast execution. Thanks to our professional CNC milling technology and staff, we can produce precise and complex molds quickly and efficiently. When it comes to responding to urgent requirements, we can be *flexible and proactive* to meet our customers' needs."
              },
              possibilities: {
                title: "We fulfil your milling wishes",
                text: "We are able to manufacture a wide range of parts and components, both simple plates and complex components such as mounts for lasers and electrodes. We are oriented towards you and would like to fulfill your wishes. Our experienced professionals can develop customized solutions and prototypes to meet the individual needs of our customers. Do not hesitate to contact us and tell us your requirements, we will do our best to realize your desired parts."
              }
            },

            other_services: {
              title: "Professional services - included!",
              subtitle: "",
              text: "In addition to our CNC manufacturing services, our company also offers a range of specialized services, including trovalizing, deburring, micro deburring, surface finishing and vibratory grinding.",
              in_house: {
                title: "In-House Quality",
                text: "At RS-Mechanik, we pride ourselves on doing all of the manufacturing steps in-house. The manufacturing process begins with either stamping or lasering. The components are then edged and ground. After that, surface treatment is performed, either by chromating or by pressing in nuts. **The entire production process takes place in one place**, which allows *high efficiency* and *flexibility*. We can respond quickly to changes or specific requirements and ensure the quality of our products throughout the manufacturing process."
              }
            },
            specs: {
              x_axis: "X-Axis (mm)",
              y_axis: "Y-Axis (mm)",
              z_axis: "Z-Axis (mm)",
            }
          },
          carousel: {
            since_1990: {
              title: "CNC manufacturing since 1990",
              text: "For our customers we manufacture tailored requests."
            },
            certified: {
              title: "Certified Quality",
              text: "Implemented by a quality management system based on DIN EN ISO 9001"
            }
          },
          cards: {
            fast_response: {
              title: "Fast response times",
              text: "Feel free to contact us if specific requests arise, we will reply as soon as possible."
            },
            quality: {
              title: "Quality",
              text: "Continuously improving quality of our products is important for us. Our employees receive constant training for the best possible teamwork"
            },
            offers: {
              title: "Huge selection",
              text: "We offer a wide range of services, from CNC milling jobs to painting. Convince yourself!"
            },
            certified: {
              title: "Certified",
              text: "In order to meet the industry standard, we have established a quality management system based on DIN EN ISO 9001."
            }
          },
          mid_services: {
            milling: "CNC-Milling",
            turning: "CNC-Turning",
            edges: "CNC-Bending",
            laser: "CNC-Laser",
            stamping: "CNC-Punching",
            welding: "Welding",
            painting: "Painting",
            storage: "Storage",
            surfacing: "Surfacing"
          }
        } 
      },
      de: {
        translation: {
          navigation: {
            home: "Home",
            company: "Unternehmen",
            quality: "Qualität",
            contact: "Kontakt",
            imprint: "Impressum",
            privacy: "Datenschutz",
            services: "Services",
            jobs: "Jobs",
            surfacing: "Oberflächenbeschichtung",
            production: "Produktion",
            cnc_laser: "CNC Laser & Kanten",
            cnc_milling: "CNC Fräsen & Drehen",
            chromatizing: "Chromatierung",
            other_services: "Weitere Dienstleistungen",
            message404: "Diese Seite konnten wir leider nicht finden.",
            message_go_startpage: "Zurück zur Startseite",
            leave_page_title: "Wenn Sie fortfahren, wird diese Seite verlassen",
            leave_page_text: "Sollten Sie fortfahren, wird eine Drittanbieter Seite geöffnet. Inhalte werden dort nicht verwaltet. Link: ",
            button_leave: "Schließen",
            continue: "Fortfahren",
            about_us: "Über uns",
            phone: "Telefon",
            mobile: "Mobil",
            further_contact: "Weitere Kontakt Infos",
            
          },
          footer: {
            our_offers: "",
          },
          company: {
            general: {
              open_portfolio: "Zum Portfolio"
            },
            section_1: {
              title: "Über die Firma",
              text: "Im Jahre 1990 wurde das Unternehmen RS-Mechanik von Reinhold Strasser gegründet.\n Dank unserer langjährigen Erfahrung kennen wir die Ansprüche unserer Kunden bezüglich Qualität und kostengünstiger Fertigung.\nUnsere Stärke ist es, die uns gestellten Aufgaben schnell und mit hoher Präzision zu meistern. Zuverlässigkeit und Qualität werden von unseren Kunden geschätzt und bei uns groß geschrieben.\n Wir bauen auf eine langfristige Zusammenarbeit."
            },
            section_2: {
              title: "Unser Team",
              text: "Ein erfahrenes und hochqualifiziertes Team von 20 Mitarbeitern bürgt für die einwandfreie Ausführung anspruchsvoller Bearbeitungen und Montagen.\n\nAls zukunftsorientierter Betrieb sind wir immer auf der Suche nach neuen Mitarbeitern und Lehrlingen!"
            },
            section_3: {
              title: "Qualitative Fertigung",
              text: "Qualität ist für uns im Fertigungsprozess das A und O.\n \nDa eine präzise Anfertigung in der CNC Branche unverzichtbar ist, prüfen wir stetig unsere Fertigungsprozesse und passen diese bei Bedarf an."
            },
            section_4: {
              title: "Vorteilhafte Lage im Industriegebiet",
              text: "Aufgrund unserer Lage im Industriegebiet ist es uns möglich auf Wunsch Teile zur Bearbeitung an kooperierende Firmen weiterzugeben. Durch sorgfältig ausgewählten Partnern bieten wir zusätzliche Services, wie z.B. spezielle Verfahren der Oberflächenbeschichtung an. Die Lage des Firmengebäudes ermöglicht uns eine schnelle Kooperation und somit einen Gesamtprozess mit Fokus aus Qualität und schnelle Bearbeitungszeit."
            },
          },
          main: {
            about_us: {
              text: "### Über das Unternehmen \nUnser Unternehmen ist auf hochwertige CNC-Laser- und Fräsdienstleistungen für eine Vielzahl von Branchen spezialisiert. Dank unserer hochmodernen Ausrüstung und unseres qualifizierten Technikerteams sind wir in der Lage, präzise und effiziente Bearbeitungsdienstleistungen für alle Arten von Produkten anzubieten - von Prototypen bis hin zu Großserien. \n#### CNC und Blechbearbeitung \nGanz gleich, ob Sie komplexe 3D-Formen oder komplizierte Details auf einer ebenen Fläche benötigen, unsere CNC-Laser und -Fräsen können alles mit Schnelligkeit und Genauigkeit bearbeiten. Außerdem bieten wir eine Vielzahl von Materialien zur Auswahl, darunter Metalle, Kunststoffe und Verbundwerkstoffe, so dass Sie die perfekte Lösung für Ihr Projekt finden können. \n#### Hochwertiger Service \nNeben der Anfertigung von Teilen bieten wir auch Leistungen wie das Chromatieren, Eloxieren oder Lackieren für ihre Teile. Wenn Sie also zuverlässige und hochwertige CNC-Laser- und Fräsdienstleistungen benötigen, sind Sie bei uns an der richtigen Adresse. Kontaktieren Sie uns noch heute, um Ihr Projekt zu besprechen und ein Angebot einzuholen. \n#### Partner des TU Fast Racing Team der TU München \nWir sind stolz darauf, ein Partner des TU Fast Racing Team der TU München zu sein - unsere Firma arbeitet an wesentlichen Teilen der Rennkiste."
            }
          },
          imprint: {
            companyname: "RS-Mechanik GmbH u. Co. KG",
            address: "Carl-Zeiss-Str. 3 93426 Roding",
            phone: "Telefon: +49 (0)9461 2406",
            fax: "Fax: +49 (0)9461 4978",
            email: "E-Mail: info@rs-mechanik.de",
            companyseat: "Firmensitz: Roding",
            registercourt: "Registergericht: Regensburg, HRA 6626, HRB 8469",
            ceo: "Geschäftsführender Gesellschafter: Reinhold Strasser",
            leader: "Geschäftsführer: Tobias Strasser",
            ustnumber: "USt-IdNr: DE 222 841 262",
            taxnumber: "Steuer-Nummer: 211/173/01505",
            hinweis: "Angaben gemäß §5 TMG:"
          },
          contactpage: {
            messageus: "Schreiben Sie uns!",
            contactustext: "Schreiben Sie uns an, falls Sie ein Anliegen haben - wir antworten Ihnen schnellstmöglich!",
            emailaddresslabel: "Email Adresse",
            yourmessagelabel: "Ihre Nachricht",
            sendmessage: "Nachricht senden",
            enable_google_maps: {
              text: "Um den Ort von RS-Mechanik anzuzeigen wird Google Maps verwendet. Wenn Sie mit der Verwendung von Google Maps einverstanden sind, dann drücken Sie den unteren Konpf um das eingebettete Google Maps iFrame zu laden.",
              button: "Google Maps aktivieren"
            }
          },
          jobs: {
            post: "### Werden Sie Teil der RS-Mechanik Familie \nAls modernes CNC-Fertigungsunternehmen sind wir ständig auf der Suche nach kompetenten Mitarbeitern. Wir bieten unseren Angestellten Flexibilität und Freiheit in Bezug auf die Arbeitszeit: Keine Schichtarbeit und die Arbeitszeit kann unter normalen Arbeitszeiten frei gewählt werden. \n#### Positives Arbeitsklima \nWir bieten attraktive Boni für unsere Mitarbeiter und legen großen Wert auf eine angenehme Arbeitsumgebung. Wir suchen sowohl erfahrene Fachkräfte als auch Berufsanfänger, die eine Karriere in der CNC Fertigung anstreben. \n#### Jetzt bewerben! \nWenn Sie Interesse an einer Karriere in unserem Unternehmen haben, freuen wir uns auf Ihre Bewerbung samt Lebenslauf. Bitte senden Sie Ihre Unterlagen per E-Mail an "
          },
          surfacing: {
            chromatizing: {
              text: "### Robuste Beschichtung \nNeben unseren CNC-Leistungen bieten wir auch professionelle Oberflächenbehandlung durch Chromatierung an. Mit dieser Technik können wir Ihre Produkte mit einer hochwertigen, widerstandsfähigen und attraktiven Chromschicht versehen, die sie vor Korrosion und anderen Schäden schützt. \n#### Chrom-III-Passivierung mit Surtec \nWir verwenden dafür ausschließlich Produkte von dem renommierten Anbieter Surtec, um sicherzustellen, dass wir Ihnen immer die bestmögliche Qualität bieten. Unser erfahrenes Team von Technikern wird sorgfältig und professionell arbeiten, um sicherzustellen, dass Ihre Produkte perfekt verchromt werden.",
            },
            anodizing: {
              text: "### Hochwertiges Eloxieren \nUnser Unternehmen bietet einen hochwertigen Eloxieren Service zur Beschichtung von Teilen an. Eloxieren ist ein Verfahren, bei dem eine dünne Schicht aus Aluminiumoxid auf die Oberfläche von Metallteilen aufgetragen wird, um deren Korrosionsbeständigkeit, Härte und Optik zu verbessern. \n\n Wir outsourcen diesen Service und bieten ihn unseren Kunden somit direkt an. \nEloxieren ist eine zuverlässige und langlebige Beschichtungsmethode, die für eine Vielzahl von Industriezweigen geeignet ist, darunter die Automobilindustrie, die Elektronikindustrie und die Luft- und Raumfahrtindustrie. Wir sind stolz darauf, diesen Service anbieten zu können und freuen uns darauf, Ihnen bei der Beschichtung Ihrer Teile behilflich zu sein.",
            },
            painting: {
              text: "### Lackieren von Teilen \nUnser erfahrenes Team verfügt über die Fähigkeiten und das Know-how, um Ihre Teile perfekt zu lackieren, um eine hochwertige und langlebige Oberfläche zu gewährleisten.\n Ob es sich um eine kleine Serienfertigung oder um Einzelteile handelt, wir passen unser Verfahren entsprechend an und garantieren Ihnen ein perfektes Ergebnis. Wir arbeiten mit modernen Lackieranlagen und robustem Industrielack, um eine gleichmäßige und hochwertige Oberflächenbeschichtung zu gewährleisten. Im Trockenofen wird der Lack anschließend professionell eingebrannt. \nKontaktieren Sie uns gerne, falls Sie weitere Fragen haben oder ein Angebot benötigen."
            },
          },
          quality: {
            communication: {
              text: "### Kundenzufriedenheit an erster Stelle \nUnsere Kunden sind uns sehr wichtig und wir setzen alles daran, ihnen hochwertige Produkte und Dienstleistungen zu bieten. Wir sind stolz darauf, dass unsere Kunden stets zufrieden sind und wir eine hohe Kundenzufriedenheit haben. \n\nSollten jedoch einmal Probleme oder Unzufriedenheit auftreten, nehmen wir diese ernst und arbeiten eng mit unseren Kunden zusammen, um gemeinsam Lösungen zu finden. Wir glauben, dass gute Kommunikation der Schlüssel zu einer erfolgreichen Zusammenarbeit ist und setzen alles daran, offen und transparent zu kommunizieren, um sicherzustellen, dass alle Probleme schnell und effektiv gelöst werden. \n\nWir schätzen das Vertrauen, das unsere Kunden in uns setzen, und sind stolz darauf, ihnen einen hervorragenden Kundenservice zu bieten."
            },
            certified: {
              text: "### Zertifizierter Qualitätsstandard \nUnser Unternehmen ist stolz darauf, zertifiziert zu sein und höchste Qualitätsstandards zu erfüllen. Wir sind spezialisiert auf die Entwicklung von Produkten und Lösungen für die **Lebensmittel-, Medizin-, Flugzeug- und Automobilindustrie** und arbeiten eng mit unseren Kunden zusammen, um maßgeschneiderte Lösungen zu entwickeln, die ihren Anforderungen entsprechen. \n\nUnser Team besteht aus erfahrenen Experten, die sich auf die Entwicklung von Produkten für diese Branchen spezialisiert haben und über tiefgreifende Kenntnisse und Fähigkeiten in den Bereichen Materialien, Fertigungstechnologien und Qualitätsmanagement verfügen. Wir sind stolz darauf, unseren Kunden hochwertige und zuverlässige Produkte und Lösungen anbieten zu können und setzen alles daran, ihre Erwartungen zu übertreffen."
            },
            encryption: {
              text: "### Ihre Daten sind sicher. \n\nBei unserem Unternehmen legen wir größten Wert auf die Vertraulichkeit und Sicherheit aller Kundendaten und Zeichnungen. Wir verpflichten uns, alle Informationen, die uns von unseren Kunden zur Verfügung gestellt werden, strengstens vertraulich zu behandeln und keinerlei Informationen an Dritte weiterzugeben. \n\nWir haben umfangreiche Sicherheitsmaßnahmen implementiert, um sicherzustellen, dass alle Kundendaten und Zeichnungen geschützt sind und nur von autorisierten Personen zugänglich sind. Wir verstehen, dass die Vertraulichkeit von Kundendaten von großer Bedeutung ist und setzen alles daran, diese zu schützen. \n\nWir schätzen das Vertrauen, das unsere Kunden in uns setzen und führen gerne einen Dialog, falls Sie Fragen zu den Sicherheitskonzepten haben."
            }
          },
          services: {
            chrome: {
                title: "Oberflächenbehandlung durch Chromatierung",
                subtitle : "",
                text: "Neben unseren CNC-Leistungen bieten wir auch professionelle Oberflächenbehandlung durch Chromatierung an. \n\nMit dieser Technik können wir Ihre Produkte mit einer hochwertigen, widerstandsfähigen und attraktiven Chromschicht versehen, die sie vor Korrosion und anderen Schäden schützt. \n#### Chrom-III-Passivierung mit Surtec \nWir verwenden dafür ausschließlich Produkte von dem renommierten Anbieter Surtec, um sicherzustellen, dass wir Ihnen immer die bestmögliche Qualität bieten. Unser erfahrenes Team von Technikern wird sorgfältig und professionell arbeiten, um sicherzustellen, dass Ihre Produkte perfekt verchromt werden. Kontaktieren Sie uns noch heute, um mehr über unsere Chromatierungsdienste zu erfahren und wie wir Ihnen dabei helfen können, Ihre Produkte zu verbessern. Wir freuen uns darauf, von Ihnen zu hören"
            },
            general: {
                close: "Schließen",
                contact: "Kontakt"
            },
            laser: {
              section_1: "## Blechbearbeitung \nUnsere CNC-Lasern / Kanten-Abteilung ist auf die Herstellung von Gehäusen und Abdeckungen spezialisiert. Unsere Laser sind die erste Instanz in der Blechabteilung und fertigen aus Rohmaterial grundlegende Bauteile für Gehäuse und Abdeckungen. Dank unserer modernen Lasertechnologie können wir eine Vielzahl von Produkten herstellen, darunter PC-Gehäuse, medizinische Fachmaschinen, Kameras und sogar massive Stahlteile mit einer Stärke von bis zu 10mm sowie Rohr- und Heizungsbaukomponenten.",
              section_2: "## Fertigungsprozess \nIn Kombination mit unserer Kant- Stanz- Pressmaschine erlaubt der Laser uns, Gehäuse aus allen Materialien herzustellen, die später durch [Oberflächenbehandlung](../beschichtung) behandelt werden können. Unser Ziel ist es präzise und hochwertig Gehäuse und Abdeckungen herzustellen, die den Anforderungen unserer Kunden in Bezug auf Design, Haltbarkeit und Funktion gerecht werden. Der Verfahrbereich unserer Maschine, der Amada LC Alpha III liegt bei 2520 x 1550mm (X x Y Achse)"
            },
            milling: {
              title: "Professionelles CNC-Fräsen und Drehen",
              subtitle: "",
              text: "Unser Maschinenpark ist mit modernen und leistungsstarken CNC-Fräsmaschinen ausgestattet, die für eine Vielzahl von Fertigungsaufgaben geeignet sind. \n\nDabei unterstützen wir eine Vielzahl von Materialien: Über fast alle Stahlsorten und Sondermetalle wie beispielsweise Monel bis hin zu Kunststoffen wie PEEK, POM und Teflon sowie alle metallischen Werkstoffe wie Stahl, Edelstahl, Kupfer, Aluminium, Messing und mehr können beim Drehen und Fräsen eingesetzt werden.",
              fast_response: {
                title: "Schnelle Ausführung bei Prioriäts-Anfragen",
                text: "Wir sind in der Lage, schnell auf Kundenbedürfnisse zu reagieren und Fräsaufträge durchzuführen. Insbesondere in besonderen Umständen, wie beispielsweise dringenden oder priorisierten Aufträgen, können wir eine schnelle Ausführung gewährleisten. Dank unserer modernen CNC-Frästechnologie und unserem erfahrenen Fachpersonal können wir präzise und komplexe Formen schnell und effizient herstellen. Wenn es darum geht, auf dringende Anforderungen zu reagieren, können wir flexibel und proaktiv handeln, um die Anforderungen unserer Kunden zu erfüllen."
              },
              possibilities: {
                title: "Wir erfüllen ihren Bedarf",
                text: "Wir sind in der Lage, eine Vielzahl von Teilen und Bauteilen zu fertigen, sowohl einfache Platten als auch komplexe Bauteile wie beispielsweise Halterungen für Laser und Elektroden. Wir orientieren uns an Sie und möchten ihre Wünsche erfüllen. Unsere erfahrenen Fachkräfte können maßgeschneiderte Lösungen und Prototypen entwickeln, um den individuellen Anforderungen unserer Kunden gerecht zu werden. Zögern Sie nicht, uns zu kontaktieren und uns Ihre Anforderungen mitzuteilen, wir werden unser Bestes tun, um Ihre Wunsch-Teile zu realisieren."
              }
            },
            other_services: {
              title: "Professionelle Services - inklusive!",
              subtitle: "",
              text: "Unser Unternehmen bietet neben unseren CNC-Fertigungsdiensten auch eine \nReihe von spezialisierten Services an, darunter Trovalisieren, Entgraten, \nMicroentgraten, Oberflächenveredelung und Gleitschleifen.",
              in_house:
              {
                title: "Qualität im Haus",
                text: "Wir bei RS-Mechanik sind stolz darauf, alle Fertigungsschritte in-house zu erledigen. Der Fertigungsprozess beginnt entweder mit Stanzen oder Lasern. Anschließend werden die Komponenten gekantet und geschliffen. Danach wird eine Oberflächenbehandlung durchgeführt, entweder durch Chromatieren oder durch Einpressen von Muttern. **Der gesamte Produktionsprozess findet an einem Ort statt**, was eine *hohe Effizienz und Flexibilität* ermöglicht. Wir können schnell auf Änderungen oder spezifische Anforderungen reagieren und die Qualität unserer Produkte während des gesamten Fertigungsprozesses sicherstellen."
              }
            },
            specs: {
              x_axis: "X-Achse (mm)",
              y_axis: "Y-Achse (mm)",
              z_axis: "Z-Achse (mm)",
            }
          },
          carousel: {
            since_1990: {
              title: "CNC Fertigung seit 1990",
              text: "Für unsere Kunden fertigen wir angepasste Aufträge an"
            },
            certified: {
              title: "Zertifizierte Qualität",
              text: "Umgesetzt durch ein Qualitäts-Management-System basierend auf DIN EN ISO 9001"
            }
          },
          cards: {
            fast_response: {
              title: "Schnelle Antwortzeiten",
              text: "Kontaktieren Sie uns gerne bei spezifischen Aufträgen, wir antworten schnellstmöglich."
            },
            quality: {
              title: "Qualität",
              text: "Eine kontinuierlich verbessernde Qualität unsere Produkte ist für uns wichtig. Unsere Mitarbeiter bekommen stetig Schulungen für bestmögliche Teamarbeit"
            },
            offers: {
              title: "Breites Angebot",
              text: "Wir bieten eine große Auswahl an Leistungen, von CNC Fräsaufträgen bis zu Lackierungen. Überzeugen Sie sich selbst!"
            },
            certified: {
              title: "Zertifiziert",
              text: "Um dem Industrie-Standard gerecht zu werden, haben wir ein Qualitäts-Managementsystem auf der Basis der DIN EN ISO 9001 aufgebaut."
            }
          },
          mid_services: {
            milling: "CNC-Fräsen",
            turning: "CNC-Drehen",
            edges: "CNC-Kanten",
            laser: "CNC-Lasern",
            stamping: "CNC-Stanzen",
            welding: "Schweißen",
            painting: "Lackieren",
            storage: "Lagern",
            surfacing: "Oberflächen"
          }
        } 
      }
    }
  });

export default i18n;