import { t } from "i18next";
import { Container, Image, Row, Col } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import ServiceCard from "../Unternehmen/ServiceCard";

function MainText() {
    return (
        <Container className="bg-light info-section">
            <Row className="align-items-center bg-light">
                <Col sm>
                    <p data-aos="zoom-in-up">
                    <ServiceCard text={t("main.about_us.text")}></ServiceCard>
                    </p>
                </Col>
            </Row>
        </Container>
    )
}
export default MainText;