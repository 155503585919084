import ImageCarousel from "../Main/ImageCarousel";
import { useTranslation, Trans } from 'react-i18next';
import ServiceCard from "../Unternehmen/ServiceCard";
function ServicePage() {
  const {t} = useTranslation();
  return (
    <section className="bg-light">
      <section className="bg-light">
        <ImageCarousel></ImageCarousel>
      </section>
      <section className="bg-light">
        <ServiceCard blue={false} title={"services.chrome.title"} subtitle={"services.chrome.subtitle"} text={"services.chrome.text"} />
      </section>
      </section>
  );
}

export default ServicePage;
