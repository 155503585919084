import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation, Trans } from 'react-i18next';

function LeavePageModal(props) {
  const {t} = useTranslation();

  const handleClose = () => props.setShow(false);
  const handleShow = () => props.setShow(true);

  const handleContinue = () => window.open(props.link, '_blank').focus();
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("navigation.leave_page_title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("navigation.leave_page_text")} {props.link}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("navigation.button_leave")}
          </Button>
          <Button variant="primary" onClick={handleContinue}>
            {t("navigation.continue")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LeavePageModal;