import { Container, Row, Button, Card } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";

function GoogleMapsFrame(){
    const [enabled, setEnabled] = React.useState(false);
    const {t} = useTranslation();

    if(enabled){
        console.log("User accepted Google Maps")
        return (
            <iframe
          title="Google Maps RS Mechanik"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2606.166810565402!2d12.505871315594732!3d49.21636348338842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479ffda9d50eea63%3A0xd360714cecd78585!2sRS%20Mechanik%20Verwaltungs%20GmbH!5e0!3m2!1sde!2sde!4v1638845050027!5m2!1sde!2sde"
          frameborder="0"
          styles={{ border: 0 }}
          height={600}
          width={"100%"}
          allowfullscreen
          loading="lazy"
        ></iframe>
        );
    } else {
        return <div height={600}>
            <Card>
            <Row>
                <p>{t("contactpage.enable_google_maps.text")}</p>
            </Row>
            <Row>
                <Button variant="outine-secondary" onClick={() => setEnabled(true)}>{t("contactpage.enable_google_maps.button")}</Button>
            </Row>
            </Card>
        </div>
    }
}

export default GoogleMapsFrame;