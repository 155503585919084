import { Nav, Navbar, NavDropdown, Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import LogoOnly from "../assets/Logo.png"
import { Image } from "react-bootstrap";
import { useTranslation, Trans } from 'react-i18next';
import flag_germany from '../assets/img/flag-germany.svg';
import flag_england from '../assets/img/flag-england.svg';


function Navigation() {
  const switchLanguage = () =>
     {
      
      i18n.language == "en" ? i18n.changeLanguage("de") : i18n.changeLanguage("en")
     }

  const { t, i18n } = useTranslation();
  return (
    <Navbar bg="light" expand="lg">
      <Container>
      <Navbar.Brand href="/">
            <Image src={LogoOnly} height={70}>

            </Image>
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
              <Nav.Link href="/"><Trans i18nKey="navigation.home" /></Nav.Link>
              <Nav.Link href="unternehmen">{t("navigation.company")}</Nav.Link>

              <NavDropdown title={t("navigation.production")} id="navbarScrollingDropdown">
              <NavDropdown.Item href="cnc-fraesen">{t("navigation.cnc_milling")}</NavDropdown.Item>
              <NavDropdown.Item href="cnc-laser">{t("navigation.cnc_laser")}</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="beschichtung">{t("navigation.surfacing")}</NavDropdown.Item>
              <NavDropdown.Item href="other-services">{t("navigation.other_services")}</NavDropdown.Item>
            </NavDropdown>
            
              <Nav.Link href="qualitaet">{t("navigation.quality")}</Nav.Link>
              <Nav.Link href="jobs">{t("navigation.jobs")}</Nav.Link>
              <Nav.Link href="kontakt">{t("navigation.contact")}</Nav.Link>
              <Nav.Link href="imprint">{t("navigation.imprint")}</Nav.Link>
              <Nav.Link onClick={() => switchLanguage()}><Image className="dropdown-menu-end " src={i18n.language == "en" ? flag_germany : flag_england} height={20} rounded/></Nav.Link>
            </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>


  );
}

export default Navigation;