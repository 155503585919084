import { Container, Row, Col } from "react-bootstrap";
import MidService from "./MidService";
import "../ColorClasses.css";
import CNCMillingIcon from "../../../assets/img/icons/milling-machine.png";
import CNCLaserIcon from "../../../assets/img/icons/cnc-laser.png";
import CNCKantenIcon from "../../../assets/img/icons/cnc-kanten.png";
import CNCDrehenIcon from "../../../assets/img/icons/cnc-drehen.png";
import CNCStanzenIcon from "../../../assets/img/icons/cnc-stanzen.png";
import StorageIcon from "../../../assets/img/icons/storage.png";
import WeldingIcon from "../../../assets/img/icons/welding.png";
import LackierenIcon from "../../../assets/img/icons/lackieren.png";
import SurfaceIcon from "../../../assets/img/icons/surface.png";
import Img6 from "../../../assets/img/portfolio/file-6.jpg";
import {useTranslation, Trans} from "react-i18next";

function MidServices() {
    const {t} = useTranslation();

    return (
        // <section className="bg-darker-blue fullContainer p-4">
        <div className="bg-darker-blue fullContainer p-4">
        {/* // <div className="fullContainer p-4"> */}
            {/* <Row className="justify-content-center align-items-center">
                <h1 className="mt-3" style={{ color: "black" }}>
                    Unser Angebot
                </h1>
            </Row> */}
            <Row>
                <Col>
                    <MidService image={CNCMillingIcon} serviceName={t("mid_services.milling")}></MidService>
                </Col>
                <Col>
                    <MidService image={CNCDrehenIcon} serviceName={t("mid_services.turning")}></MidService>
                </Col>
                <Col>
                    <MidService image={CNCKantenIcon} serviceName={t("mid_services.edges")}></MidService>
                </Col>
            </Row>
            <Row>
                <Col>
                    <MidService image={CNCLaserIcon} serviceName={t("mid_services.laser")}></MidService>
                </Col>
                <Col>
                    <MidService image={CNCStanzenIcon} serviceName={t("mid_services.stamping")}></MidService>
                </Col>
                <Col>
                    <MidService image={WeldingIcon} serviceName={t("mid_services.welding")}></MidService>
                </Col>
            </Row>
            <Row>
                <Col>
                    <MidService image={LackierenIcon} serviceName={t("mid_services.painting")}></MidService>
                </Col>
                <Col>
                    <MidService image={StorageIcon} serviceName={t("mid_services.storage")}></MidService>
                </Col>
                <Col>
                    <MidService image={SurfaceIcon} serviceName={t("mid_services.surfacing")}></MidService>
                </Col>
            </Row>
        </div>
    )

}
export default MidServices;