import ImageCarousel from "./Main/ImageCarousel";
import { useTranslation, Trans } from 'react-i18next';
function NotFoundPage() {
  const {t} = useTranslation();
  return (
    <section className="bg-light">
      <section className="bg-light">
        <ImageCarousel></ImageCarousel>
      </section>
      <section className="bg-light">
        <h2 className="pt-5">{t("navigation.message404")}</h2>
        <a href="/">{t("navigation.message_go_startpage")}</a>
      </section>
      </section>
  );
}

export default NotFoundPage;
