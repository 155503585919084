import { Container, Image, Row, Col } from "react-bootstrap";
import ImageCarousel from "../Main/ImageCarousel";

import InfoSection1 from "./InfoComponents/InfoSection1";
import InfoSection2 from "./InfoComponents/InfoSection2";
import InfoSection3 from "./InfoComponents/InfoSection3";
import InfoSection4 from "./InfoComponents/InfoSection4";
import "../ColorClasses.css";

function InfoText() {
  return (
    <section className="bg-light">

      <ImageCarousel></ImageCarousel>
      <InfoSection1></InfoSection1>
      <InfoSection2></InfoSection2>
      <InfoSection3></InfoSection3>
      <InfoSection4></InfoSection4>
    </section>
  );
}
export default InfoText;
