import { Container, Image, Row, Col } from "react-bootstrap";
import TeamBild from "../../../../assets/img/file-team.jpg";

import "./InfoSection.css"
import { useTranslation, Trans } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

function InfoSection2() {
    const {t} = useTranslation();

    return (
        <section id="section-2" className="bg-darker-blue info-section text-white">
            <Row data-aos="zoom-in-up" className="align-items-center p-5 bg-darker-blue">
                <Col sm>
                    <Image data-aos="zoom-in-up" className="p-2" src={TeamBild} height={400} fluid></Image>
                </Col>
                <Col sm>
                    <h1 data-aos="zoom-in-up" style={{ color: "white" }}>
                        {t("company.section_2.title")}
                    </h1>

                    <ReactMarkdown className="" data-aos="zoom-in-up" style={{ color: "white" }}>
                        {t("company.section_2.text")}
                    </ReactMarkdown>
                </Col>
            </Row>
        </section>
    )
}
export default InfoSection2;