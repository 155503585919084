
import GalleryComponent from "../GalleryComponents/GalleryComponent";
import ImageCarousel from "./Main/ImageCarousel";
import Posting from "../Pages/Jobs/Posting";


function JobsPage() {
    return (
        <section className="bg-light">
            <ImageCarousel>
            </ImageCarousel>
            <Posting />
        </section>

    )
}

export default JobsPage;