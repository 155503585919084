import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { Row, Col, Container } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useTranslation, Trans } from 'react-i18next';
import LeavePageModal from '../../LeavePageModal';
import React, { useState } from 'react';



function handleMoreInfo(){
    alert("Pressed more info")
}
function CNCMachineCard(props) {
  const {t} = useTranslation();
  const [show, setShow] = useState(false);
  function handleMoreInfo(){
    setShow(true);

  }
  return (
    // margin: 0 auto; /* Added */
    //     float: none; /* Added */
    //     margin-bottom: 10px; /* Added */
    <>
    <Card className={"mt-4 shadow p-3 mb-5 bg-white rounded"} style={{ borderRadius: '20%', width: '18rem', margin: "0 auto", float: 'none', marginBottom: "10px"  }}>
      <Card.Img className="" variant="top" src={props.image} style={{borderRadius: '2%'}} />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text>
          {props.text}
        </Card.Text>
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroup.Item>{t("services.specs.x_axis")}: {props.entries[0]}</ListGroup.Item>
        <ListGroup.Item>{t("services.specs.y_axis")}: {props.entries[1]}</ListGroup.Item>
        {props.entries[2] && <ListGroup.Item>{t("services.specs.z_axis")}: {props.entries[2]}</ListGroup.Item>}
        {/* third entry is conditional (Some machines only have X/Y Verfahrenswege) */}
      </ListGroup>
      <Card.Body>
        <Card.Link onClick={handleMoreInfo}>More Info</Card.Link>
      </Card.Body>
    </Card>
    <LeavePageModal link={props.link} show={show} setShow={setShow} />
    </>
  );
}

export default CNCMachineCard;