import { Container, Row, Col, Carousel } from "react-bootstrap";
import "./ImpressumPage.css";
import "./ColorClasses.css";
import Img25 from "../../assets/img/file-25.jpg";
import ImpressumBild from "./Allgemein/ImpressumBild";
import { useTranslation, Trans } from 'react-i18next';

function ImpressumPage() {
  const { t } = useTranslation();

  return (
    <section className="bg-light">
      <ImpressumBild caption="Impressum"></ImpressumBild>
      <section className="bg-mid-blue font-white p-5">
        {t("imprint.hinweis")}
      </section>
      <Row>
        <Col className="bg-mid-blue font-white p-5 justify-content-center">
          <Row>{t("imprint.companyname")}</Row>
          <Row>{t("imprint.address")}</Row>
          <Row>{t("imprint.phone")}</Row>
          <Row>{t("imprint.fax")}</Row>
          <Row>{t("imprint.email")}</Row>
          {/* <Row>RS-Mechanik GmbH u. Co. KG</Row>
          <Row>Carl-Zeiss-Str. 3 93426 Roding</Row>
          <Row>Tel.: +49 (0)9461-2406</Row>
          <Row>Fax: +49 (0)9461-4978</Row>
          <Row>E-Mail: info@rs-mechanik.de</Row> */}

        </Col>
        <Col className="bg-mid-blue font-white p-5">
        <Row>{t("imprint.companyseat")}</Row>
        <Row>{t("imprint.registercourt")}</Row>
        <Row>{t("imprint.ceo")}</Row>
        <Row>{t("imprint.leader")}</Row>
        <Row>{t("imprint.ustnumber")}</Row>
        <Row>{t("imprint.taxnumber")}</Row>
          {/* <Row>Sitz der Gesellschaft: Roding</Row>
          <Row>Registergericht: Regensburg, HRA 6626, HRB 8469</Row>
          <Row>Geschäftsführender Gesellschafter: Reinhold Strasser</Row>
          <Row>USt-IdNr.: DE 222 841 262</Row>
          <Row>Steuer-Nr.: 211/173/01505</Row> */}
        </Col>
      </Row>
      </section>
  );
}
export default ImpressumPage;
