import { Card } from "react-bootstrap";
import { useTranslation, Trans } from 'react-i18next';
import ReactMarkdown from 'react-markdown'

function ServiceCard(props){
    const {t} = useTranslation();
    return (
    <Card className={"shadow p-3 mb-5 bg-white rounded " + (props.blue ? "bg-primary text-white" : "bg-light")} style={{ marginTop: 20, padding: 5, width: "90%", marginLeft: "auto", marginRight: "auto", whiteSpace: "pre-line" }}>
        <Card.Body>
        <Card.Title>{t(props.title)}</Card.Title>
        {props.subtitle && <Card.Subtitle className="mb-2 text-muted">{t(props.subtitle)}</Card.Subtitle>}
        <Card.Text>
            
            <ReactMarkdown>{t(props.text)}</ReactMarkdown>
            {props.table && props.table}
        </Card.Text>

        {props.links && props.links.map((current_link) => <Card.Link href="#">{current_link}</Card.Link>)}
        </Card.Body>
    </Card>
    )
}

export default ServiceCard;