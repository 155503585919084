import './App.css';
import Navigation from './components/Navigation';
import MainPage from './components/Pages/MainPage';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import UnternehmenPage from './components/Pages/UnternehmenPage'
import NotFoundPage from './components/Pages/NotFoundPage'
import KontaktPage from './components/Pages/KontaktPage'
import ImpressumPage from './components/Pages/ImpressumPage'
import FooterComponent from './components/Pages/FooterComponent';
import React from 'react';
import AOS from 'aos';
import QualitaetPage from './components/Pages/QualitaetPage';
import DatenschutzPage from './components/Pages/DatenschutzPage';
import ChromatierenPage from './components/Pages/Production/ChromatierenPage';
import CNCDrehenPage from './components/Pages/Production/CNCDrehenPage';
import CNCLaserPage from './components/Pages/Production/CNCLaserPage';
import CNCFraesenPage from './components/Pages/Production/CNCFraesenPage';
import OtherServicesPage from './components/Pages/Production/OtherServicesPage';
import SurfacingPage from './components/Pages/SurfacingPage';
import JobsPage from './components/Pages/JobsPage';

class App extends React.Component {
  componentDidMount() {
    AOS.init();
  }

  render() {


    return (
      <div className="App bg-light">
        <Router>

          <Navigation></Navigation>
          <Routes>

            <Route exact path="/" element={<MainPage />} />
            <Route path="/unternehmen" element={<UnternehmenPage />} />
            <Route path="/unternehmen.html" element={<UnternehmenPage />} />
            <Route path="/qualitaet" element={<QualitaetPage />} />
            <Route path="/qualitaet.html" element={<QualitaetPage />} />
            <Route path="/imprint" element={<ImpressumPage />} />
            <Route path="/impressum" element={<ImpressumPage />} />
            <Route path="/kontakt" element={<KontaktPage />} />
            <Route path="/kontakt.html" element={<KontaktPage />} />
            <Route path="/datenschutz" element={<DatenschutzPage />} />
            <Route path="/cnc-fraesen" element={<CNCFraesenPage />} />
            <Route path="/impressum" element={<ImpressumPage />} />
            <Route path="/beschichtung" element={<SurfacingPage />} />
            <Route path="/jobs" element={<JobsPage />} />
            <Route path="/cnc-laser" element={<CNCLaserPage />} />
            <Route path="/leistungen.html" element={<OtherServicesPage />} />
            <Route path="/other-services" element={<OtherServicesPage />} />
            <Route path="*" element={<NotFoundPage />} />
            
          </Routes>
          <FooterComponent></FooterComponent>
        </Router>

      </div>
    );
  }
}

export default App;
