import { Carousel } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Img21 from "../../../assets/img/file-21.jpg"
import Img25 from "../../../assets/img/file-25.jpg"
import Img27 from "../../../assets/img/file-27.jpg"

import { useTranslation, Trans } from "react-i18next"
import CarouselItem from "./CarouselItem";

function ImageCarousel() {
    const { t } = useTranslation();
    const imageInterval = 2500;
    return (


        <section className="bg-light">
            <Carousel>
                {/* <CarouselItem title={t("carousel.since_1990.title")} text={t("carousel.since_1990.text")} image={Img21} />
                <CarouselItem title={t("carousel.certified.title")} text={t("carousel.certified.text")} image={Img27} /> */}

                <Carousel.Item interval={imageInterval}>
                    <img
                        style={{ objectFit: "cover" }}
                        className="d-block w-100"
                        src={Img21}
                        height={400}
                    />
                    <Carousel.Caption>
                        <h3>{t("carousel.since_1990.title")}</h3>
                        <p>{t("carousel.since_1990.text")}</p>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item interval={imageInterval}>
                    <img
                        style={{ objectFit: "cover" }}
                        className="d-block w-100"
                        src={Img27}
                        height={400}
                    />
                    <Carousel.Caption>
                        <h3>{t("carousel.certified.title")}</h3>
                        <p>{t("carousel.certified.text")}</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </section>
    )
}

export default ImageCarousel;