import { Container, Image, Row, Col } from "react-bootstrap";
import GebaeudeBild from "../../../../assets/img/file-23.jpg";
import "./InfoSection.css"
import { useTranslation, Trans } from 'react-i18next';

function InfoSection1() {
    const {t} = useTranslation();
    return (
        <Container className="bg-light info-section">
            <Row className="align-items-center p-5 bg-light">
                <Col sm>
                    <h2 data-aos="zoom-in-up">{t("company.section_1.title")}</h2>
                    <p data-aos="zoom-in-up">
                        {t("company.section_1.text")}
                    </p>
                </Col>
                <Col sm>
                    <Image data-aos="zoom-in-up" className="p-2" src={GebaeudeBild} height={400} fluid></Image>
                </Col>
            </Row>
        </Container>
    )
}
export default InfoSection1;