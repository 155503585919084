import { Card } from "react-bootstrap";
import { Col } from "react-bootstrap";
function MainCard(props) {
    return (
        <Col className="d-flex align-items-stretch" xs={12} md={6}>
        <Card data-aos="zoom-in-up" className="shadow p-2 mb-5 bg-white rounded">
            <Card.Body>
                <Card.Title className="h4 mb-2"><i className={props.cardIcon}> {props.cardTitle}</i></Card.Title>
                <Card.Text>
                    {props.cardText}
                </Card.Text>
            </Card.Body>
        </Card>
        </Col>
    )
}
export default MainCard;