import ImageCarousel from "./Main/ImageCarousel";
import Chromatizing from "./Surfacing/Chromatizing";
import Anodizing from "./Surfacing/Anodize";
import Painting from "./Surfacing/Painting";



function SurfacingPage() {
    return (
        <section className="bg-light">
            <ImageCarousel>
            </ImageCarousel>
            <Chromatizing />
            <Anodizing />
            <Painting />
            
        </section>

    )
}

export default SurfacingPage;