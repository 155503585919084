import { Container, Image, Row, Col } from "react-bootstrap";
import GebaeudeBild from "../../../../assets/img/file-23.jpg";
// import "./InfoSection.css"
import { useTranslation, Trans } from 'react-i18next';
import { isElementOfType } from "react-dom/test-utils";
import ReactMarkdown from 'react-markdown';

function LaserSection(props) {
    const {t} = useTranslation();
    
    return (
        <section className={props.blue ? "bg-darker-blue info-section" : "bg-light info-section"}>
            <Row className={props.blue ? "align-items-center p-5 bg-darker-blue" : "align-items-center p-5 bg-light"}>
                {props.blue && (
                    <>
                    <Col sm className="text-white">
                    <ReactMarkdown data-aos="zoom-in-up">
                        {props.text}
                    </ReactMarkdown>
                </Col>
                {props.image && <Col sm>
                    <Image data-aos="zoom-in-up" className="p-2" src={props.image} height={400} fluid></Image>
                </Col>}
                </>
                )}
                {!props.blue && 
                 <>
                 {props.image &&
                 <Col sm>
                 <Image data-aos="zoom-in-up" className="p-2" src={props.image} height={400} fluid></Image>
             </Col>}
                 <Col sm>
                 <ReactMarkdown data-aos="zoom-in-up">
                     {props.text}
                 </ReactMarkdown>
             </Col>
             
             </>
                }
            </Row>
        </section>
    )
}
export default LaserSection;