import PictureComponent from "./Main/PictureComponent";


import { Container } from "react-bootstrap";
import InfoText from "./Unternehmen/InfoText";


function UnternehmenPage() {
    return (
        <section className="bg-light">
            <InfoText></InfoText>
            </section>

    )
}

export default UnternehmenPage;