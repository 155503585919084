import GalleryElement from './GalleryElement';
import { Container, Row } from 'react-bootstrap';

function GalleryComponent(props) {
    return (
        <Container className="p-3">
            <Row>
                {Object.entries(props.images).map(([name, image], id) => {
                    // Return the element. Also pass key    
                    return (

                        <GalleryElement image={image} imgLink={"/assets/img/portfolio/" + name} />
                    )
                })}
            </Row>
        </Container>
    )
}
export default GalleryComponent;