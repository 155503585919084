import ImageCarousel from "../Main/ImageCarousel";
import { useTranslation, Trans } from 'react-i18next';
import ServiceCard from "../Unternehmen/ServiceCard";
import CNCSpecsTable from "./CNCSpecsTable";
import CNCMachineCard from "./CNCMachineCard";
import milling_machine from "../../../assets/img/cnc/milling_machine.jpeg";
import hurco_logo from "../../../assets/img/hurco-logo.png";
import hurco_test from "../../../assets/img/hurco-test.png";
import { Row, Col } from "react-bootstrap";
import VMX30i from "../../../assets/img/cnc/VMX30i.jpeg"
import VMX42i from "../../../assets/img/cnc/VMX42i.jpeg"
import VMX50i from "../../../assets/img/cnc/VMX50i.jpeg"
import VMX64i from "../../../assets/img/cnc/VMX64i.jpeg"

const machines = [
  {
    name: "Hurco VMX64i",
    info: "",
    entries: [1.625,864, 762], // . ist ein komma oder nicht? Flo fragen.
    infotext: "",
    picture: VMX64i,
    link: "https://www.hurco.de/produkte/3-achs-bearbeitungszentren/hochleistung/vmx-64-i/"
  },
  {
    name: "Hurco VMX50i",
    info: "",
    entries: [1.270, 660, 610],
    infotext: "",
    picture: VMX50i,
    link: "https://www.hurco.de/produkte/3-achs-bearbeitungszentren/hochleistung/vmx-50-i/"
  },
  {
    name: "Hurco VMX30i",
    info: "",
    entries: [762,508,610],
    infotext: "",
    picture: VMX30i,
    link: "https://www.hurco.de/produkte/3-achs-bearbeitungszentren/hochleistung/vmx-30-i/"
  },
  // {
  //   name: "Hurco VMX30iX3",
  //   info: "",
  //   entries: "",
  //   infotext: "",
  //   picture: ""
  // },
 
]

// Maschinen ohne Bilder
const machines2 = [
  {
    name: "Hurco VMX30T",
    info: "",
    entries: [760,510,610],
    infotext: "",
    picture: ""
  },
  {
    name: "Hurco VMX42I",
    info: "",
    entries: [1.067,610,610],
    infotext: "",
    picture: VMX42i,
    link: "https://www.hurco.de/produkte/3-achs-bearbeitungszentren/hochleistung/vmx-42-i/"
  },
  {
    name : "Hurco VM1",
    info: "hurcovm1.info",
    entries: [762, 508, 610],
    infotext: "hurcovm1.infotext",
    picture: ""
  }
]

function renderMachines(machines) {
  return (
    <section className="bg-light">
      <Row style={{justifyContent: "center", alignItems: "center"}}>
        {machines.map((machine) => <Col key={machine.name} xs={12} sm={6} md={6} lg={4} xl={3}><CNCMachineCard image={machine.picture} link={machine.link} title={machine.name} text={machine.text} entries={machine.entries} infotext={machines.infotext}/></Col>)}
      </Row>
    </section>
    );
}

// Diese Unterseite wird für CNC Fräsen und Drehen benutzt
function ServicePage() {
  const {t} = useTranslation();
  return (
    <section className="bg-light">
      <section className="bg-light">
        <ImageCarousel></ImageCarousel>
      </section>
      <section className="">
        <ServiceCard blue={false} title={"services.milling.title"} subtitle={"services.milling.subtitle"} text={"services.milling.text"} />

      </section>
        
          {machines && renderMachines(machines)}
      <section className="">
        <ServiceCard blue={false} title={"services.milling.fast_response.title"} text={"services.milling.fast_response.text"} />
      </section>
      {machines2 && renderMachines(machines2)}
      <section className="">
        <ServiceCard blue={false} title={"services.milling.possibilities.title"} text={"services.milling.possibilities.text"} />
      </section>
    </section>
  );
}

export default ServicePage;
