import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { useTranslation, Trans } from 'react-i18next';
import EncryptionImage from "../../../assets/img/encryption.jpeg";
import ReactMarkdown from 'react-markdown';

function Section() {
    const {t} = useTranslation();
    return (
        <Container className="bg-light info-section">
            <Row data-aos="zoom-in-up" className="align-items-center p-5 bg-light">
                <Col sm>
                    <ReactMarkdown>{t("quality.encryption.text")}</ReactMarkdown>
                </Col>
                <Col sm>
                    <Image className="p-2" src={EncryptionImage} height={400} fluid></Image>
                </Col>
            </Row>
        </Container>
    )
}
export default Section;