import { Image, Container } from "react-bootstrap";
import MainPicture from "../../../assets/img/file-23.jpg";

function PictureComponent() {
    return (
        <Container>
            <Image height={250} src={MainPicture} fluid className="p-5">

            </Image>
        </Container>
    )
}
export default PictureComponent;