import Table from 'react-bootstrap/Table';
import { useTranslation, Trans } from 'react-i18next';

function CNCSpecsTable(props) {
  const {t} = useTranslation();

  return (
    <Table striped="columns" responsive className='m-2'>
      <thead>
        <tr>
          <th>{t("services.specs.x_axis")}</th>
          <th>{t("services.specs.y_axis")}</th>
          <th>{t("services.specs.z_axis")}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
            {props.entries && props.entries.map((entry) => <td>{entry}</td>)}
        </tr>
      </tbody>
    </Table>
  );
}

export default CNCSpecsTable;